/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { IHapUserContext, NmsPrivilege } from "@nuance/hap-components";
import { HuxFeatureSet, HuxFeatureSettings } from "@nuance/hux-components";
import { PREVIEW_MODE_STORAGE_KEY } from "../../NCCConstants";
import { AppConfig } from "../../services/AppConfigSvc";
import { NccFeatureMap } from "./NccFeatures";
import { getLogger } from "@nuance/hux-diagnostics";
import NccServiceName from "./NccServiceName";

/**
 * Computes effective feature set based on app config feature set and local storage
 * @param appConfig Application configuration
 * @param userContext Current user context
 * @returns HuxFeatureSettings
 */
export const computeEffectiveAppFeatureSet = (
    appConfig: AppConfig,
    userContext: IHapUserContext
): HuxFeatureSettings => {
    const logger = getLogger();
    const envMaxFeatureSet = appConfig.featureSet;
    const userMaxFeatureSet = getMaxUserFeatureSet(userContext);
    const previewModeOn = isPreviewModeOn(envMaxFeatureSet);
    let effectiveFeatureSet: HuxFeatureSet;
    // if we're in QA/Local, turn preview mode on by default
    if (previewModeOn) {
        // if preview mode is on
        if (envMaxFeatureSet === HuxFeatureSet.Developer) {
            // if we're in QA or dev, give developer features
            effectiveFeatureSet = HuxFeatureSet.Developer;
        } else {
            // otherwise, minimum between user's max features and env max features
            effectiveFeatureSet = Math.min(envMaxFeatureSet, userMaxFeatureSet);
        }
    } else {
        // if preview mode is off
        effectiveFeatureSet = HuxFeatureSet.GeneralUser;
    }

    const disabledServices = appConfig.disabledServices ?? [];
    // if there are disabledServices, turn those features off
    if (disabledServices && disabledServices.length > 0) {
        logger.logInformation("Disabled services found in app config.");
        for (const disabledService of disabledServices) {
            switch (disabledService as keyof typeof NccServiceName) {
                case NccServiceName.ClientSettings:
                    // Hierarchical settings should be disabled
                    NccFeatureMap.ProductSettings = HuxFeatureSet.Disabled;
                    break;
                case NccServiceName.NccAnalytics:
                    NccFeatureMap.Analytics = HuxFeatureSet.Disabled;
                    break;
                case NccServiceName.NccWorkflow:
                    // user import disabled
                    NccFeatureMap.UserImport = HuxFeatureSet.Disabled;
                    NccFeatureMap.UserImportEnhancements = HuxFeatureSet.Disabled;
                    break;
                default: // if service name is invalid, do nothing
                    logger.logWarning(
                        "Invalid service name found in 'disabledServices' array in app config."
                    );
                    break;
            }
            NccFeatureMap[disabledService] = HuxFeatureSet.Disabled;
        }
    }

    return new HuxFeatureSettings(effectiveFeatureSet, NccFeatureMap);
};

/**
 * Calculate the maximum featureset the user can view based on their privileges and organization.
 * @param userContext The user's userContext.
 * @returns The allowed featureset.
 */
function getMaxUserFeatureSet(userContext: IHapUserContext) {
    if (userContext.isNuanceOrg || userContext.hasPrivilege(NmsPrivilege.NCCBetaUser)) {
        return HuxFeatureSet.BetaTester;
    } else if (userContext.hasPrivilege(NmsPrivilege.NCCEarlyAdopter)) {
        return HuxFeatureSet.EarlyAdopter;
    } else {
        return HuxFeatureSet.GeneralUser;
    }
}

/**
 * Check local storage for whether preview mode should be on.
 * @param envMaxFeatureSet - The environment max feature set. If this is set to developer, then preview mode will be on.
 * @returns True if preview mode is on, false otherwise.
 */
export function isPreviewModeOn(envMaxFeatureSet?: HuxFeatureSet) {
    if (envMaxFeatureSet === HuxFeatureSet.Developer) return true;
    try {
        return JSON.parse(localStorage.getItem(PREVIEW_MODE_STORAGE_KEY) ?? "") === true;
    } catch {
        return false;
    }
}
