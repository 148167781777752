/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { CommandBarButton, IPanelProps, IRenderFunction, Panel, PanelType } from "@fluentui/react";
import {
    HapOrgExploration,
    HapOrgExplorationLevels,
    HapOrgExplorationType
} from "@nuance/hap-components";
import {
    HuxPanelStylesOpaqueHeaderBackgroundWithNavigation,
    ITreeNode
} from "@nuance/hux-components";
import { useTranslation } from "react-i18next";

export interface IChangeGroupPanelProps {
    /**
     * The current organization
     */
    org: { name: string; uid: number; guid: string };
    /**
     * the item that should be rendered as selected.
     */
    selectedNode?: ITreeNode;
    /**
     * Callback function for when the panel is closed or an item is selected.
     */
    onClosePanel: (node: ITreeNode | undefined) => void;
}

/**
 * This component to contain a panel with HapOrgExploration inside it.
 * The panel will be used for changing the level at which product settings are rendered.
 * @param props - The props for the component.
 * @returns - The component
 */
export function ChangeGroupPanel(props: IChangeGroupPanelProps) {
    const { t } = useTranslation();
    const onRenderNavigationContent: IRenderFunction<IPanelProps> = React.useCallback(
        (navigationProps, defaultRender) => (
            <>
                <CommandBarButton
                    style={{ paddingLeft: "24px" }}
                    text={t("Form.Back_Button")}
                    iconProps={{ iconName: "Back" }}
                    onClick={() => props.onClosePanel(undefined)}
                />
                {defaultRender ? defaultRender(navigationProps) : null}
            </>
        ),
        []
    );
    return (
        <Panel
            headerText={t("SelectObject.Select_Group_Title")}
            onRenderNavigationContent={onRenderNavigationContent}
            type={PanelType.medium}
            isOpen={true}
            onDismiss={() => {
                props.onClosePanel(undefined);
            }}
            styles={HuxPanelStylesOpaqueHeaderBackgroundWithNavigation}
            closeButtonAriaLabel={t("Action.Close_Button")}
            isLightDismiss={true}
        >
            <HapOrgExploration
                selectableLevels={[HapOrgExplorationLevels.Group]}
                org={props.org}
                onSelect={node => {
                    props.onClosePanel(node);
                }}
                selectedKey={
                    props.selectedNode
                        ? `${props.selectedNode.uid}/${props.selectedNode.level}`
                        : undefined
                }
                type={HapOrgExplorationType.Classic}
            ></HapOrgExploration>
        </Panel>
    );
}
