/**
 * @copyright (C) Microsoft Corporation.
 * All Rights Reserved.
 */

const NccServiceName = {
    NccWorkflow: "NccWorkflow",
    NccAnalytics: "NccAnalytics",
    ClientSettings: "ClientSettings"
};

export default NccServiceName;
